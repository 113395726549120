import React from "react"
import { Link } from "gatsby"

import YAMLdata from "./home-consulting.yaml"

const HomeConsulting = () => {
  return (
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>{YAMLdata.title}</h2>
          <p>{YAMLdata.content}</p>
          <p>
            <br />
          </p>
          <Link to="/consulting" className="genric-btn circle arrow">
            Find out how?
          </Link>
        </div>
      </div>
    </section>
  )
}
export default HomeConsulting
