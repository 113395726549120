import React from "react"
import { Link } from "gatsby"
import DynamicImage from "../components/dynimage"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeConsulting from "../sections/home-consulting"

const PrivateLte = () => (
  <Layout>
    <SEO title="Private LTE" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_07">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>Private LTE</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#">Private LTE</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>Choose Your Network Integrator</h2>
          <p>
            There are a wide variety of radio’s available to choose from
            depending on the coverage area and power output sought. For
            convenience its useful to think of private LTE radios in two
            categories: Residential/SoHO/Enterprise: - 3G Radio which supports
            Band 1/2/5 and supports HSPA with maximum data speed support of 5.7
            Mbps. Maximum number of allowed users are 8/16. SME/Enterprise: - 4G
            Radio which supports Band 1/4/5/7/8/12/13/14/17/20/28 and supports
            maximum data speed support of 50 Mbps. Maximum number of allowed
            users are 16/132.
          </p>
        </div>
      </div>
    </section>
    {/* <!--================Feature Area =================--> */}
    <section class="feature_area p_120">
      <div class="container">
        <div class="main_title">
          <h2>Why Private LTE?</h2>
          <p>
            This solution helps enterprises cut down the time to rollout a best
            roaming experience and have the control to build aggressive future
            roadmaps and rollouts. It overcomes the challenges of dealing with
            multiple vendors, technologies and ever evolving customer demands.
          </p>
        </div>
        <div class="feature_inner row">
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <div className="mb-4 feat_image">
                <DynamicImage src="solutions/pvt-lte_01.jpg" />
              </div>
              <p>
                Private LTE uses the same technology as GSM 4G/LTE, but the
                service is provided in a closed network context. The network
                makes use of micro towers and small cells to replicate the
                public network.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <div className="mb-4 feat_image">
                <DynamicImage src="solutions/pvt-lte_02.jpg" />
              </div>
              <p>
                As the network is a ‘closed’ network it is more secure against
                potential security issues and immune from outage issues
                associated with the public LTE networks.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <div className="mb-4 feat_image">
                <DynamicImage src="solutions/pvt-lte_03.jpg" />
              </div>
              <p>
                A significant customer benefit is that we can create the private
                network environment to replicate roaming networks, in order to
                solve roaming incidents. This environment is a full-fledged
                roaming network, without having to involve any public mobile
                network operators, thus makes things quicker and easier to
                identify issues in their device certification process.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <div className="mb-4 feat_image">
                <DynamicImage src="solutions/pvt-lte_04.jpg" />
              </div>
              <p>
                While this particular application is for an airline, the
                approach can be applied to any business with a need for
                private-LTE connections. This could include industrial and
                manufacturing companies, smart campus type applications, retail
                applications or warehousing and distribution.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Feature Area =================--> */}

    {/* <!--================Feature Area =================--> */}
    <section class="feature_area p_120">
      <div class="container">
        <div class="main_title">
          <h2>How can you benefit?</h2>
          <p>
            Private-LTE is a dedicated LTE network that serves a specific
            enterprise business, government agency or educational outfit, who
            can own and operate the system. Private-LTE (and Private 5G) is
            regarded as a significant opportunity for the telecoms industry
            providing Cost-effective, Management flexibility and Increased
            Secured networks.
          </p>
          <br />
          <p>
            Enterprise business can use private-LTE services to help it manage
            device certification and trouble shooting. Using public LTE networks
            involved having to get potentially multiple parties involved in
            detecting and correcting faults during device testing. This involved
            a lot of extra time and resources for the customer. With the
            private-LTE service this is now all under the control of the
            Enterprise, so they only need to work with one partner.
          </p>
        </div>
        <div class="feature_inner row">
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <i class="flaticon-money-bag"></i>
              <h4>Reduce Upfront Costs</h4>
              <p>
                Deploy a single network component or an entire LTE network from
                our extensive product portfolio. Alternatively start with a
                single product and incrementally add new products over time as
                your business plan dictates.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <i class="flaticon-speedometer"></i>
              <h4>Speedy Implementations</h4>
              <p>
                PaaS helps in speedy implementations of LTE network elements and
                fast integration with existing Network, which reduces the
                teething issues of coordination and timing/availability from
                different parties. The usual expected time to launch a network
                with us now is as less as weeks.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <i class="flaticon-plus"></i>
              <h4>Highly Available And Scalaable</h4>
              <p>
                PaaS which is built on virtualization technology, so resources
                are hosted on Highly-Available network setup with provision of
                Geo-redundancy and can easily be scaled up or down as your
                business changes.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex align-content-stretch">
            <div class="feature_item">
              <i class="flaticon-test"></i>
              <h4>Test New Devices And Modules</h4>
              <p>
                Private LTE and 5G NSA PaaS could be used to certify modules and
                help customers choose the appropriate modules from their
                manufacturers. Certifying them for aggressiveness, continuity,
                quality, and standard compliances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Feature Area =================--> */}
    <HomeConsulting />
  </Layout>
)

export default PrivateLte
